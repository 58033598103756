<template>
  <div
    class="ml-auto mr-auto p-2  md:p-6 bg-white rounded-lg mb-4 md:max-w-3/4"
  >
    <div v-if="loadingSpinner" class="text-center">
      <svg
        class="inline animate-spin mr-2"
        width="25"
        height="25"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M48 25C48 12.2975 37.7025 2 25 2"
          stroke="black"
          stroke-width="4"
        />
        <path
          d="M48 25C48 37.7025 37.7025 48 25 48"
          stroke="white"
          stroke-width="4"
        />
        <path
          d="M2 25C2 12.2975 12.2975 2 25 2"
          stroke="white"
          stroke-width="4"
        />
        <path
          d="M25 48C12.2975 48 2 37.7025 2 25"
          stroke="#C9000C"
          stroke-width="4"
        />
      </svg>
    </div>
    <h1 class="font-bold text-lg text-center text-darkblue mb-3">
      {{ $route.params.mode == "new" ? "New Expense" : "Update Expense" }}
    </h1>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(SubmitExpense)" class="">
        <div class=" mb-3 md:mb-6 justify-around flex flex-col md:flex-row">
          <div class="w-full md:w-5/12 mb-2">
            <label class="block md:text-xs" for="Unit">
              Expense Item
            </label>
            <validation-provider rules="required" v-slot="{ errors }">
              <t-rich-select
                name="Expense Item"
                :required="true"
                :classes="rich_select_default_component_classes"
                v-model="expenseItemId"
                placeholder="Select Expense Item"
                :fixedClasses="rich_select_component_classes"
                :options="expenseItemOptions"
              ></t-rich-select>
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="w-full md:w-5/12 mb-2">
            <label class="block md:text-xs" for="Unit">
              User making the Expense
            </label>
            <validation-provider rules="required" v-slot="{ errors }">
              <t-rich-select
                name="User"
                :required="true"
                v-model="userId"
                placeholder="Select User"
                :classes="rich_select_default_component_classes"
                :fixedClasses="rich_select_component_classes"
                :options="userOptions"
              ></t-rich-select>
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>

        <div class=" mb-3 md:mb-6 justify-around flex ">
          <div class="w-full md:w-5/12 mb-2">
            <label class="block md:text-xs" for="Amount">Amount (₦) </label>
            <validation-provider
              rules="required|numeric|min_value:1"
              v-slot="{ errors }"
            >
              <input
                type="number"
                v-model="amount"
                name="Amount"
                class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>

        <button
          type="submit"
          class="text-white block md:text-xs bg-darkblue hover:text-black hover:bg-green-400 pl-3 pt-2 pb-2 pr-3 ml-auto mr-auto rounded-full"
        >
          <svg
            v-if="loading.expense"
            class="inline animate-spin mr-2"
            width="25"
            height="25"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48 25C48 12.2975 37.7025 2 25 2"
              stroke="black"
              stroke-width="4"
            />
            <path
              d="M48 25C48 37.7025 37.7025 48 25 48"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M2 25C2 12.2975 12.2975 2 25 2"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M25 48C12.2975 48 2 37.7025 2 25"
              stroke="#C9000C"
              stroke-width="4"
            />
          </svg>
          {{ $route.params.mode == "new" ? "Create Expense" : "Save Expense" }}
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import gql from "graphql-tag";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { mapMutations } from "vuex";

  export default {
    name: "ManageExpenses",
    props: {},
    components: { ValidationProvider, ValidationObserver },
    apollo: {
      expense: {
        query: gql`
          query expense($id: ID!) {
            expense(id: $id) {
              _id
              expenseItemId {
                _id
                expenseItemName
              }

              staffId {
                _id
                name
              }
              amount
              createdAt
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },
        skip() {
          return this.$route.params.mode != "edit";
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loadingSpinner = true;
            return;
          }

          this.loadingSpinner = false;
        },
      },
      getExpenseItems: {
        query: gql`
          query getExpenseItems {
            getExpenseItems {
              _id
              expenseItemName
            }
          }
        `,

        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loadingSpinner = true;
            return;
          }

          this.loadingSpinner = false;
        },
      },
      getUsers: {
        query: gql`
          query getUsers {
            getUsers {
              _id
              serialId
              name
            }
          }
        `,

        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loadingSpinner = true;
            return;
          }

          this.loadingSpinner = false;
        },
      },
    },
    data() {
      return {
        userId: null,
        amount: 0,
        loading: {
          expense: false,
        },
        expense: [],
        expenseItemId: null,
        getExpenseItems: [],
        getUsers: [],
        loadingSpinner: false,
        rich_select_default_component_classes: {
          wrapper: "",
          buttonWrapper: "",
          selectButton:
            "px-3 rounded-lg  md:text-xs  py-2 text-black transition duration-100 ease-in-out bg-white border-darkblue border-2 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
          selectButtonLabel: "",
          selectButtonPlaceholder: "text-darkblue",
          selectButtonIcon: "text-gray-600",
          selectButtonClearButton:
            "hover:bg-blue-100 text-gray-600 rounded transition duration-100 ease-in-out",
          selectButtonClearIcon: "",
          dropdown:
            "-mt-1 bg-white  md:text-xs  border-b border-gray-300 border-l border-r rounded-b shadow-sm",
          dropdownFeedback: "pb-2  md:text-xs  px-3 text-gray-400 text-sm",
          loadingMoreResults: "pb-2 px-3 text-gray-400 text-sm",
          optionsList: "",
          searchWrapper: "p-2 placeholder-gray-400",
          searchBox:
            "px-3 py-2 bg-gray-50 text-sm  md:text-xs  rounded border focus:outline-none focus:shadow-outline border-gray-300",
          optgroup: "text-gray-400 uppercase text-xs py-1 px-2 font-semibold",
          option: "",
          disabledOption: "",
          highlightedOption: "bg-blue-100",
          selectedOption:
            "font-semibold bg-gray-100 bg-blue-500 font-semibold text-white",
          selectedHighlightedOption:
            "font-semibold bg-gray-100 bg-darkblue font-semibold text-white",
          optionContent: "flex justify-between items-center px-3 py-2",
          optionLabel: "",
          selectedIcon: "",
          enterClass: "",
          enterActiveClass: "opacity-0 transition ease-out duration-100",
          enterToClass: "opacity-100",
          leaveClass: "transition ease-in opacity-100",
          leaveActiveClass: "",
          leaveToClass: "opacity-0 duration-75",
        },
        rich_select_component_classes: {
          wrapper: "relative",
          buttonWrapper: "inline-block relative w-full",
          selectButton: "w-full flex text-left justify-between items-center",
          selectButtonLabel: "block truncate",
          selectButtonPlaceholder: "block truncate",
          selectButtonIcon: "fill-current flex-shrink-0 ml-1 h-4 w-4",
          selectButtonClearButton:
            "flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6",
          selectButtonClearIcon: "fill-current h-3 w-3",
          dropdown: "absolute w-full z-10",
          dropdownFeedback: "",
          loadingMoreResults: "",
          optionsList: "overflow-auto",
          searchWrapper: "inline-block w-full",
          searchBox: "inline-block w-full",
          optgroup: "",
          option: "cursor-pointer",
          disabledOption: "opacity-50 cursor-not-allowed",
          highlightedOption: "cursor-pointer",
          selectedOption: "cursor-pointer",
          selectedHighlightedOption: "cursor-pointer",
          optionContent: "",
          optionLabel: "truncate block",
          selectedIcon: "fill-current h-4 w-4",
          enterClass: "",
          enterActiveClass: "",
          enterToClass: "",
          leaveClass: "",
          leaveActiveClass: "",
          leaveToClass: "",
        },
      };
    },
    watch: {
      expense: {
        handler: function(newValue) {
          this.expenseItemId = newValue.expenseItemId._id;
          this.userId = newValue.staffId._id;
          this.amount = newValue.amount;
        },
        deep: true,
      },
    },
    computed: {
      expenseItemOptions: function() {
        return this.getExpenseItems.map((expenseItem) => {
          return {
            value: expenseItem._id,
            text: expenseItem.expenseItemName,
          };
        });
      },
      userOptions: function() {
        return this.getUsers.map((user) => {
          return {
            value: user._id,
            text: user.name,
          };
        });
      },
    },
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      SubmitExpense() {
        if (this.$route.params.mode != "edit") {
          this.CreateExpense();
          return;
        }
        this.UpdateExpense();
      },
      async CreateExpense() {
        this.loading.expense = true;
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($expense: ExpenseInput!) {
                createExpense(expense: $expense) {
                  _id
                }
              }
            `,
            // Parameters
            variables: {
              expense: {
                expenseItemId: this.expenseItemId,
                staffId: this.userId,
                amount: Number(this.amount),
              },
            },
          });

          this.loading.expense = false;

          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == "BAD_USER_INPUT";
            });
            return this.$emit("show-alert", {
              type: "error",
              message: errorResponse.message,
            });
          }

          if (!errors && !!data.createExpense._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Expense created successfully`,
            });

            return this.$router.push("/app/expenses");
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating expenses,check your network or contact your developer`,
          });
        } catch (error) {
          this.loading.expense = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating expenses,check your network or contact your developer`,
          });
        }
      },
      async UpdateExpense() {
        this.loading.expense = true;

        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($id: ID!, $expense: ExpenseInput!) {
                updateExpense(id: $id, expense: $expense) {
                  _id
                  serialId
                  amount
                  staffId {
                    _id
                  }
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
              expense: {
                expenseItemId: this.expenseItemId,
                staffId: this.userId,
                amount: Number(this.amount),
              },
            },
          });

          this.loading.expense = false;

          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == "BAD_USER_INPUT";
            });
            return this.$emit("show-alert", {
              type: "error",
              message: errorResponse.message,
            });
          }

          if (!errors && !!data.updateExpense._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Expense updated successfully`,
            });

            return this.$router.push("/app/expenses");
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error updating expenses,check your network or contact your developer`,
          });
        } catch (error) {
          this.loading.expense = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error updating stock,check your network or contact your developer`,
          });
        }
      },
    },
    mounted() {
      this.setBreadcrumbs([
        { displayName: "Expenses", pathName: "expenses" },
        ...(this.$route.params.mode == "edit"
          ? [
              {
                displayName: "Single Expense",
                pathName: "singleExpense",
                params: { id: this.$route.params.id },
              },
            ]
          : []),
        {
          displayName: "Manage Expense",
          pathName: "manageExpense",
          params: { id: this.$route.params.id, mode: this.$route.params.mode },
        },
      ]);
      this.$apollo.queries.getUsers.setOptions({
        fetchPolicy: "network-only",
      });
      this.$apollo.queries.getExpenseItems.setOptions({
        fetchPolicy: "network-only",
      });
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
