var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-auto mr-auto p-2  md:p-6 bg-white rounded-lg mb-4 md:max-w-3/4"},[(_vm.loadingSpinner)?_c('div',{staticClass:"text-center"},[_c('svg',{staticClass:"inline animate-spin mr-2",attrs:{"width":"25","height":"25","viewBox":"0 0 50 50","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M48 25C48 12.2975 37.7025 2 25 2","stroke":"black","stroke-width":"4"}}),_c('path',{attrs:{"d":"M48 25C48 37.7025 37.7025 48 25 48","stroke":"white","stroke-width":"4"}}),_c('path',{attrs:{"d":"M2 25C2 12.2975 12.2975 2 25 2","stroke":"white","stroke-width":"4"}}),_c('path',{attrs:{"d":"M25 48C12.2975 48 2 37.7025 2 25","stroke":"#C9000C","stroke-width":"4"}})])]):_vm._e(),_c('h1',{staticClass:"font-bold text-lg text-center text-darkblue mb-3"},[_vm._v(" "+_vm._s(_vm.$route.params.mode == "new" ? "New Expense" : "Update Expense")+" ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.SubmitExpense)}}},[_c('div',{staticClass:" mb-3 md:mb-6 justify-around flex flex-col md:flex-row"},[_c('div',{staticClass:"w-full md:w-5/12 mb-2"},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"Unit"}},[_vm._v(" Expense Item ")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('t-rich-select',{attrs:{"name":"Expense Item","required":true,"classes":_vm.rich_select_default_component_classes,"placeholder":"Select Expense Item","fixedClasses":_vm.rich_select_component_classes,"options":_vm.expenseItemOptions},model:{value:(_vm.expenseItemId),callback:function ($$v) {_vm.expenseItemId=$$v},expression:"expenseItemId"}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full md:w-5/12 mb-2"},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"Unit"}},[_vm._v(" User making the Expense ")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('t-rich-select',{attrs:{"name":"User","required":true,"placeholder":"Select User","classes":_vm.rich_select_default_component_classes,"fixedClasses":_vm.rich_select_component_classes,"options":_vm.userOptions},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:" mb-3 md:mb-6 justify-around flex "},[_c('div',{staticClass:"w-full md:w-5/12 mb-2"},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"Amount"}},[_vm._v("Amount (₦) ")]),_c('validation-provider',{attrs:{"rules":"required|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.amount),expression:"amount"}],staticClass:"border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block",attrs:{"type":"number","name":"Amount"},domProps:{"value":(_vm.amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.amount=$event.target.value}}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('button',{staticClass:"text-white block md:text-xs bg-darkblue hover:text-black hover:bg-green-400 pl-3 pt-2 pb-2 pr-3 ml-auto mr-auto rounded-full",attrs:{"type":"submit"}},[(_vm.loading.expense)?_c('svg',{staticClass:"inline animate-spin mr-2",attrs:{"width":"25","height":"25","viewBox":"0 0 50 50","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M48 25C48 12.2975 37.7025 2 25 2","stroke":"black","stroke-width":"4"}}),_c('path',{attrs:{"d":"M48 25C48 37.7025 37.7025 48 25 48","stroke":"white","stroke-width":"4"}}),_c('path',{attrs:{"d":"M2 25C2 12.2975 12.2975 2 25 2","stroke":"white","stroke-width":"4"}}),_c('path',{attrs:{"d":"M25 48C12.2975 48 2 37.7025 2 25","stroke":"#C9000C","stroke-width":"4"}})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$route.params.mode == "new" ? "Create Expense" : "Save Expense")+" ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }